<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.user") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div
        class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-4"
      >
        <div class="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <div
            v-if="waiting"
            class="aspect-square flex w-60 max-w-full animate-pulse items-center justify-center rounded-full bg-slate-300"
          />
          <img
            v-else-if="user.avatar"
            class="w-60 max-w-full rounded-full object-cover"
            :src="getAvatar(user)"
          />
          <div
            v-else
            class="aspect-square flex w-60 max-w-full items-center justify-center rounded-full bg-slate-300"
          >
            <UserIcon class="h-3/4 w-3/4 text-white" />
          </div>
          <div class="mt-4 flex justify-center">
            <form-button
              :command="() => console.log('changeImage')"
              :disabled="waiting"
              id="change-avatar-button"
              label="change_avatar"
              textVariant="white"
              type="button"
              variant="indigo"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4">
          <div>
            <label class="block">{{ t("labels.first_name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.firstName }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.last_name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.lastName }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.language") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else-if="user.language">{{
              getLanguage(user.language)
            }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:col-span-2">
          <div>
            <label class="block">{{ t("labels.email") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.email }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.phone") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.phone }}</span>
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end gap-2">
        <form-button
          :command="() => toggleStatus(user.id)"
          :disabled="waiting"
          id="toggle-status-button"
          :label="user.status == 'active' ? 'deactivate' : 'activate'"
          textVariant="white"
          type="button"
          :variant="user.status == 'active' ? 'rose' : 'indigo'"
        />
        <form-button
          :command="
            () =>
              route.params.companyId
                ? router.push(
                    `/companies/${company.id}/personnel/${user.id}/edit`
                  )
                : router.push(`/personnel/${user.id}/edit`)
          "
          :disabled="waiting"
          id="profile-edit-button"
          label="edit"
          textVariant="white"
          type="button"
          variant="indigo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import { getAvatar } from "../utils/imageUtils";
import { getLanguage } from "../utils/languageUtils";
export default {
  components: { Breadcrumb, FormButton, Skeleton, UserIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const user = computed(() => store.state.user.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!user.value.id || user.value.id != route.params.userId) {
        store.dispatch("user/getUser", route.params.userId);
      }
      if (
        route.params.companyId &&
        (!company.value.id || company.value.id != route.params.companyId)
      ) {
        store.dispatch("company/getCompany", route.params.companyId);
      }
    });

    return {
      company,
      getAvatar,
      getLanguage,
      route,
      router,
      t,
      toggleStatus: (userId) => store.dispatch("user/toggleStatus", userId),
      user,
      waiting,
    };
  },
};
</script>
